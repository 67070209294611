import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const priceDefaultClassNames = /* tw */ {
    container: 'price',
    container__custom: '',
    basicPricePrimary: 'price__primary-content flex flex-wrap items-end w-full',
    basicPricePrimary__custom: '',
    basicPriceSecondary: 'price__secondary-content w-full',
    basicPriceSecondary__custom: '',
    basicPrice: 'price__basic text-black font-bold',
    basicPrice__custom: '',
    basicPrice_note: 'price__note font-normal text-sm text-gray-70',
    basicPriceWithoutTax: 'price__secondary font-normal text-xs text-gray-60',
    basicPriceWithoutTax_note: 'price__note font-normal text-xs text-gray-60',
    referenceContent: 'price__content-reference order-1 w-full sm:order-2 sm:w-auto flex items-end',
    referencePrice: 'price__reference text-gray-60 font-normal line-through',
    referencePrice__custom: '',
    discountContent: 'price__content-discount ml-5 self-center relative',
    discount: 'price__discount inline-block py-[.4rem] leading-[1.4] mt-2 px-6 text-tag text-center font-normal bg-red text-white rounded-full',
    // cena v detailu produktu /  v modálním detailu produktu
    primary: {
        container: 'flex flex-wrap gap-2',
        basicPrice: 'text-3xl order-2 sm:order-1 sm:mr-5',
        referencePrice: 'text-sm mb-2 sm:mb-0',
        discount: 'mb-2 sm:mb-0',
    },
    // cena u produktů ve fulltextovém našeptávači, fullscreen galerii, dropdown produktu (Košík), výpisu nejprodávanějších produktů, výpisu bonusových produktů v detailu produktu a ve sticky orderBoxu na detailu produktu (deskop verze)
    secondary: {
        container: 'flex gap-4 items-center',
        basicPrice: 'text-s',
        referencePrice: 'text-xs',
    },
    // cena v běžné produktové dlaždici
    tertiary: {
        container: 'flex gap-4 flex-wrap items-center',
        basicPrice: 'text-base',
        referencePrice: 'text-sm',
    },
    // cena ve sliderové produktové dlaždici, dropdown produktu (Porovnání, Oblíbené), porovnávaných produktech, v dílčích cenách v podstranním sloupci v košíku/sdíleném košíku
    quaternary: {
        container: 'flex gap-6 items-center',
        basicPrice: 'text-sm',
        referencePrice: 'text-xs',
    },
    // cena v boxu košíku (nákupní košík, sdílený košík), na detailu objednávky u hlavní souhrnné ceny, v košíku u hlavní souhrnné ceny
    quinary: {
        basicPricePrimary: 'price__primary-content block',
        container: 'block',
        basicPrice: 'text-lg text-right !font-semibold',
        referencePrice: 'text-xs',
    },
    // cena u produktu v produktové tabulce (košík, sdílený košík, ...), ve výpisu produktu v reklamacích, ve výpisu produktů v objednávce
    senary: {
        container: 'flex flex-col gap-0 items-end',
        basicPrice: 'text-base',
        referencePrice: 'text-xs',
    },
    // cena u bonusového produktu v produktové tabulce, ve výpisu faktur, na detailu objednávky (order policies, dílčí souhrnné ceny), cena dopravy/platby
    septenary: {
        container: 'flex flex-col gap-0 items-end',
        basicPrice: 'text-sm !font-semibold',
        referencePrice: 'text-xs',
    },
    // cena ve sticky orderBoxu na detailu produktu (mobile verze)
    octonary: {
        container: 'flex flex-col sm:flex-row gap-1 sm:gap-6 items-start sm:items-center',
        basicPrice: 'text-sm !font-semibold order-2 sm:order-1',
        referencePrice: 'text-xs order-1 sm:order-2',
    },
    // cena v modálním okně u bonusového produktu
    nonary: {
        container: 'flex flex-col gap-0',
        basicPrice: 'text-3xl order-2',
        referencePrice: 'text-sm order-1',
    },
};
export function priceClassNames(...args) {
    return getMemoizedFinalClassnames('priceClassNames', priceDefaultClassNames, ...args);
}
