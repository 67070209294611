import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { formatCurrency } from '@edeeone/juan-core/utils/formatCurrency';
import { priceClassNames } from './Price.styles';
import { getPrices } from './Price.utils';
// prefix suffix
export const Price = ({ prices, styles = CONFIG?.componentSettings?.juanCore?.Price?.styles, showSecondaryPrice = CONFIG?.componentSettings?.juanCore?.Price
    ?.showSecondaryPrice, withoutTaxIsPrimary = CONFIG?.componentSettings?.juanCore?.Price
    ?.withoutTaxIsPrimary, view = CONFIG?.componentSettings?.juanCore?.Price?.view ?? 'short', design = CONFIG?.componentSettings?.juanCore?.Price?.design ?? 'primary', showFrom = CONFIG?.componentSettings?.juanCore?.Price?.showFrom, showShortDiscount = CONFIG?.componentSettings?.juanCore?.Price
    ?.showShortDiscount, showCompleteDiscount = CONFIG?.componentSettings?.juanCore?.Price
    ?.showCompleteDiscount, showTaxInfo = CONFIG?.componentSettings?.juanCore?.Price?.showTaxInfo, showSecondaryTaxInfo = CONFIG?.componentSettings?.juanCore?.Price
    ?.showSecondaryTaxInfo ?? showTaxInfo, zeroPriceText = CONFIG?.componentSettings?.juanCore?.Price?.zeroPriceText, size = CONFIG?.componentSettings?.juanCore?.Price?.size, as = CONFIG?.componentSettings?.juanCore?.Price?.as ?? 'p', }) => {
    const { t } = useI18n('Price');
    const Element = as;
    const finalStyles = priceClassNames(styles, design, size);
    const { locale, currency } = useLocation();
    const { discountAmount, discountPercent, primaryPriceForSaleFormatted, secondaryPriceForSaleFormatted, primaryPriceBeforeDiscountFormatted, } = getPrices(prices, withoutTaxIsPrimary, zeroPriceText, locale, t('saleEnded'));
    const finalShowFrom = showFrom ?? !!prices?.multiplePricesForSaleAvailable;
    const fromText = finalShowFrom ? `${t('priceFrom')}\u00A0` : '';
    const primaryTaxText = withoutTaxIsPrimary ? t('withoutTax') : t('withTax');
    const secondaryTaxText = withoutTaxIsPrimary ? t('withTax') : t('withoutTax');
    const discountAmountFormatted = formatCurrency(discountAmount, currency, {
        locales: locale,
    });
    // short view
    if (view === 'short') {
        return (_jsxs(Element, { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [_jsxs("strong", { className: twMergeJuan(finalStyles.basicPrice, finalStyles.basicPrice__custom), children: [fromText, primaryPriceForSaleFormatted] }), primaryPriceBeforeDiscountFormatted && (_jsx("span", { className: twMergeJuan(finalStyles.referencePrice, finalStyles.referencePrice__custom), children: primaryPriceBeforeDiscountFormatted })), showShortDiscount && discountAmount != null && discountAmount > 0 && (_jsx("span", { className: finalStyles.discountContent, children: _jsx("span", { className: finalStyles.discount, children: t('discount', {
                            percent: discountPercent,
                            amount: discountAmountFormatted,
                        }) }) }))] }));
    }
    // complete view
    return (_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [_jsxs("div", { className: twMergeJuan(finalStyles.basicPricePrimary, finalStyles.basicPricePrimary__custom), children: [_jsxs("p", { className: twMergeJuan(finalStyles.basicPrice, finalStyles.basicPrice__custom), children: [fromText, primaryPriceForSaleFormatted, showTaxInfo &&
                                !(prices?.priceForSale?.priceWithTax == 0 && zeroPriceText) && (_jsxs("span", { className: finalStyles.basicPrice_note, children: ["\u00A0", primaryTaxText] }))] }), primaryPriceBeforeDiscountFormatted && (_jsxs("div", { className: finalStyles.referenceContent, children: [_jsx("span", { className: twMergeJuan(finalStyles.referencePrice, finalStyles.referencePrice__custom), children: primaryPriceBeforeDiscountFormatted }), showCompleteDiscount &&
                                discountAmount != null &&
                                discountAmount > 0 && (_jsx("div", { className: finalStyles.discountContent, children: _jsx("span", { className: finalStyles.discount, children: t('discount', {
                                        percent: discountPercent,
                                        amount: discountAmountFormatted,
                                    }) }) }))] }))] }), showSecondaryPrice &&
                !(prices?.priceForSale?.priceWithTax == 0 && zeroPriceText) && (_jsx("div", { className: twMergeJuan(finalStyles.basicPriceSecondary, finalStyles.basicPriceSecondary__custom), children: _jsxs("p", { className: finalStyles.basicPriceWithoutTax, children: [fromText, secondaryPriceForSaleFormatted, showSecondaryTaxInfo && (_jsxs("span", { className: finalStyles.basicPriceWithoutTax_note, children: ["\u00A0", secondaryTaxText] }))] }) }))] }));
};
