import { formatCurrency } from '@edeeone/juan-core/utils/formatCurrency';
import { normalizeNumber } from '../../utils/normalizeNumber';
export const calculateDiscountPercent = function (priceForSale, referencePrice) {
    let finalReferencePrice = normalizeNumber(referencePrice);
    let finalPriceForSale = normalizeNumber(priceForSale);
    if (finalReferencePrice == null ||
        finalPriceForSale == null ||
        finalReferencePrice === 0) {
        return undefined;
    }
    return Math.floor(100 - (finalPriceForSale / finalReferencePrice) * 100);
};
export const calculateDiscountAmount = function (priceForSale, referencePrice) {
    let finalReferencePrice = normalizeNumber(referencePrice);
    let finalPriceForSale = normalizeNumber(priceForSale);
    if (finalReferencePrice == null || finalPriceForSale == null) {
        return undefined;
    }
    return finalReferencePrice - finalPriceForSale;
};
const round = function (value, decimals = 2) {
    if (value == null) {
        return undefined;
    }
    return Number(Math.round(Number(normalizeNumber(value) + 'e' + decimals)) +
        'e-' +
        decimals);
};
export const getPrices = function (prices, withoutTaxIsPrimary = false, zeroPriceText, locale, saleEndedText) {
    const priceKey = withoutTaxIsPrimary ? 'priceWithoutTax' : 'priceWithTax';
    const secondaryPriceKey = withoutTaxIsPrimary
        ? 'priceWithTax'
        : 'priceWithoutTax';
    const currency = prices?.priceForSale?.currency;
    const currencyFormatOptions = {
        currency: currency ?? 'EUR',
        // roundingIncrement: maximumFractionDigits > 0 ? 10 : undefined,
        locales: locale,
    };
    const primaryPriceForSale = round(prices?.priceForSale?.[priceKey]);
    const primaryPriceBeforeDiscount = round(prices?.allPricesForSale?.[0]?.accompanyingPrice?.[priceKey]);
    const secondaryPriceForSale = round(prices?.priceForSale?.[secondaryPriceKey]);
    const discountAmount = calculateDiscountAmount(primaryPriceForSale, primaryPriceBeforeDiscount);
    const discountPercent = calculateDiscountPercent(primaryPriceForSale, primaryPriceBeforeDiscount);
    const showZeroPriceText = primaryPriceForSale === 0 && zeroPriceText;
    let primaryPriceForSaleFormatted = showZeroPriceText
        ? zeroPriceText
        : formatCurrency(primaryPriceForSale, currency, currencyFormatOptions);
    let secondaryPriceForSaleFormatted = showZeroPriceText
        ? zeroPriceText
        : formatCurrency(secondaryPriceForSale, currency, currencyFormatOptions);
    if (prices?.attributes?.saleEnded) {
        primaryPriceForSaleFormatted = saleEndedText;
        secondaryPriceForSaleFormatted = saleEndedText;
    }
    let primaryPriceBeforeDiscountFormatted = null;
    if (discountPercent != null && discountPercent > 0) {
        primaryPriceBeforeDiscountFormatted = formatCurrency(primaryPriceBeforeDiscount, currency, currencyFormatOptions);
    }
    return {
        primaryPriceForSale,
        primaryPriceBeforeDiscount,
        secondaryPriceForSale,
        discountAmount,
        discountPercent,
        primaryPriceForSaleFormatted,
        secondaryPriceForSaleFormatted,
        primaryPriceBeforeDiscountFormatted,
    };
};
